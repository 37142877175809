<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';



/**
 * Add-product component
 */
export default {
    page: {
        title: "Locations",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,

    },

    data() {
        return {

            title: "Locations",
            locations: [],
            
            loading: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            search: '',
            table_data: {
                draw: 0,
                length: 10,
                search: '',
                paginated: true
            },
            
        };
    },

    watch: {
        
        
    },

    mounted() {
        this.fetchLocations();
        //    console.log(this.changeDateTimeTimeZone(new Date(),"Pacific/Niue"))
    },
    methods: {
        fetchLocations() {
            this.loading = true;
            this.$axios.get("/get-all-location").then((response) => {
                this.locations = response.data.successData;
                this.loading = false
                console.log(this.locations, 'Locations');
                // this.locations = data;
            }).catch(error => {
                self.handleErrorResponse(error.response, "error");
            }).finally(function () {
            });
        },

    },
    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="card">
            <div class="card-body">
                <div class="row col-12">
                    <div class="col-md-4 mx-auto me-0">
                        <b-form-group id="input-group-2" label="Search" label-for="input-2">
                            <b-form-input id="input-2" v-model="search" placeholder="Search"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-12 mb-0">
                        <div class="table-responsive mb-0">
                            <table role="table" aria-busy="true" aria-colcount="9"
                                class="table b-table table table-centered datatable mt-3 table-bordered" id="__BVID__44">
                                <!----><!---->
                                <thead role="rowgroup" class=""><!---->
                                    <tr role="row" class="bg-transparent">
                                        <th role="columnheader" class="position-relative">
                                            <div>Location Id</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>Location Name</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>Address</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>State</div>
                                        </th>
                                        <th role="columnheader-relative">
                                            <div>ZIP Code</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>Created At</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">

                                    <tr role="row" v-for="(item, index) in locations" :key="index">
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.id}}</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.name}}</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.address}}</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.state}}</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.zipcode}}</div>
                                        </th>
                                        <th role="columnheader" class="position-relative">
                                            <div>{{item.created_at}}</div>
                                        </th>
                                    </tr>

                                    <tr role="row" class="b-table-busy-slot" v-if="loading">
                                        <td colspan="9" role="cell" class="">
                                            <p class="text-center align-middle"><span><i
                                                        class="fa-2x fa-spin fa-spinner fas primaryColor"></i></span></p>
                                        </td>
                                    </tr>
                                </tbody><!---->
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </Layout>
</template>
